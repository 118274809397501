import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import { removeNumberPrefix } from '../../../../../utilities'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'
import Testimonials from '../../../../../components/testimonials/Testimonials'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.childMarkdownRemark.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          fixedTitleWidth={true}
          disableMaxHeight={true}
          title={'Firemná web<br>stránka Dentique'}
          description={'Prípadová štúdia'}
          description2={'Novootvorená špičková zubná klinika potrebovala dostať do povedomia. Pomoha im v tom moderná webstránka a branding.'}
          text={'Náš tím sa postaral o názov brandu, logo, UX, webový dizajn a kompletné programovanie na mieru. Súčasťou webu je aj rezervačný systém na objednávanie termínov na kliniku.'}
          img={data.landingImage}
          pageMode={false}
          noShadow={true}
          what_we_done={[
            { text: 'Tvorba názvu' },
            { text: 'logo & branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (wordpress, php, javascript)'},
          ]}/>

        <AboutProject
          title={'Profesionálna webstránka na mieru nevznikne sama'}
          text={'Toto všetko obnáša tvorba webstránok, ktoré klientom úspešne a dlhodobo zarábajú.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Od klientky sme dostali zadanie na vytvorenie luxusnej <a class="blue-link" href="/tvorba-webov">firemnej stránky</a>, ktorá by novým i stálym klientom zrozumiteľne poskytla informácie o klinike, ponuke služieb a cenách. Najskôr sme si s klientkou prešli všetky požiadavky, aby sme <b>pochopili jej očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť prezentáciu kliniky od A po Z, ktorá by prekonala konkurenciu, bola by ľahko zapamätateľná a mala kvalitné <a class="blue-link" href="/ux-ui-dizajn">UX</a>. Muselo byť už na prvý klik zrejmé, že ide o prvotriedne služby a zároveň mať najlepšiu technologickú úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientkou diskutovali, ako tieto plány zrealizovať. ',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa vymyslel názov firmy, logo a <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia webstránky) a finálneho webdizajnu. Pravidelne sme klientke prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne naprogramovali na systém <a class="blue-link" href="/wodpress-a-woocommerce">Wordpress</a>.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <ProjectShortDescription
          fixedTitleWidth={true}
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientkou sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'}/>

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid}/>
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#1464fd', tintColorCode: '#4382fd', nameColor: '#FFFFFF', name: 'Azúrová modrá' },
          { code: '#343f49', tintColorCode: '#585f66', nameColor: '#FFFFFF', name: 'Antracitová' },
          { code: '#6b7a89', tintColorCode: '#a5afb8', nameColor: '#FFFFFF', name: 'Bridlicová sivá' },
          { code: '#c3c9cf', tintColorCode: '#dfe3e6', nameColor: '#6B7B8A', name: 'Signálna šedá' },
        ]}/>

        <FontShowcase
          className={'bottom-space'}
          fontName={'Azo Sans'}
          text={`Azo Sans je geometrické bezpätkové písmo, inšpirované konštruktivistickými písmami dvadsiatych rokov. Jeho nuansy zjemňujú striktnosť čistej geometrie a robia ho viac ľudským a príjemným na čítanie, obzvlášť pri dlhších textoch.`}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/azo-sans.svg'}
        />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálna webstránka sa bez neho nezaobíde.'}
          text={'Prepracované UX, high-fidelity wireframy a webový dizajn na mieru spĺňajú všetko, čo by mal moderný firemný web obsahovať. To všetko zvyšuje dôveryhodnosť a mení návštevníkov na zákazníkov. Perfektná responzívnosť a mobile first prístup sú už len čerešničkou na torte.'}
          projectDetail={'/nase-prace/webstranky/firemne/dentique'}
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))} />
        
        {/* All images */}
        <Reveal>
        <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
          <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>

        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a class='red-link' href='/ux-ui-dizajn'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný web a chce, aby mu prinášal čo najväčší zisk."
        />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

          {/* Testimonial */}
         <Reveal otherClassName="mt-5">
            <Testimonials
                references={data.saidAboutUs.childMarkdownRemark.frontmatter.references.filter(r => r.author.search('Flipwork') > -1)}
                addContainer={true}
                enableSaidAboutUs={false}
                sliderClassName='my-0'
                className="pb-0"
            />
          </Reveal>

         <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.dentique.sk" target='_blank' className='link-secondary'>www.dentique.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Game Days'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/game-days/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  saidAboutUs: file(relativePath: {eq: "pages/sk/homepage/said_about_us.md"}) {
      childMarkdownRemark {
          frontmatter {
              text_paragraph
              references {
                  description
                  logo: logo {
                      childImageSharp {
                          fluid(maxWidth: 200) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
                  logoBig: logo {
                      childImageSharp {
                          fluid(maxWidth: 200) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                }
                  author
              }
          }
      }
  }
  project:  file(relativePath: {eq: "posts/case-studies/2020-07-16_dentique.md"}) {
        childMarkdownRemark {
          frontmatter {
            banner_url
              screens {
                  href {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                              presentationWidth
                          }
                      }
                  }
                  alt
              }
          }
        }
    }
    landingImage: file(relativePath: { eq: "case-studies/dentique/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/dentique/logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/dentique/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/dentique/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
